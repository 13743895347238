import React from 'react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import css from './App.css';
import Grid from './Grid';

library.add(fas);

const App = () => {
    return (
        <div className={css.root}>
            <Grid/>
        </div>
    );
};

export default App;
