import { ATTRIBUTES } from "../util/constants";

export default {
    attributes: {
        [ATTRIBUTES.STRENGTH]: {
            bonus: 0,
            points: 0
        },
        [ATTRIBUTES.DEXTERITY]: {
            bonus: 0,
            points: 0
        },
        [ATTRIBUTES.CONSTITUTION]: {
            bonus: 0,
            points: 0
        },
        [ATTRIBUTES.INTELLIGENCE]: {
            bonus: 0,
            points: 0
        },
        [ATTRIBUTES.WISDOM]: {
            bonus: 0,
            points: 0
        },
        [ATTRIBUTES.CHARISMA]: {
            bonus: 0,
            points: 0
        }
    },
    points: {
        total: 0
    }
};
