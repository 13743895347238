import * as types from "./actionTypes";
import { limits, costs } from "../util/constants";
import { updatePoints } from "./pointAction";

export const updateRacialBonus = (attribute, value) => {
    return {
        type: types.UPDATE_RACIAL_BONUS,
        data: {
            attribute,
            value
        }
    };
};

export const increaseAttribute = attribute => {
    return (dispatch, getState) => {
        //Check on total allowed points
        const { attributes, points } = getState();
        const total = limits.attribute.min + attributes[attribute].points;

        const newPoints = attributes[attribute].points + 1;
        const pointCost = costs.find(
            c => c.from <= newPoints && newPoints <= c.to
        )?.value;

        if (
            total < limits.attribute.max &&
            points.total + pointCost <= limits.maxPoints
        ) {
            dispatch({ type: types.INCREASE_ATTRIBUTE, data: { attribute } });
            dispatch(updatePoints(points.total + pointCost));
        }
    };
};

export const decreaseAttribute = attribute => {
    return (dispatch, getState) => {
        const { attributes, points } = getState();
        const currentPoints = attributes[attribute].points;
        if (currentPoints > 0) {
            const pointCost = costs.filter(
                c => c.from <= currentPoints && currentPoints <= c.to
            )[0].value;

            dispatch({ type: types.DECREASE_ATTRIBUTE, data: { attribute } });
            dispatch(updatePoints(points.total - pointCost));
        }
    };
};

export const resetAttributes = () => {
    return { type: types.RESET_ATTRIBUTES };
};
