import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import css from "./AttributeRow.css";
import {
    updateRacialBonus,
    decreaseAttribute,
    increaseAttribute
} from "../actions/attributeActions";
import { limits } from "../util/constants";

const AttributeRow = ({ attribute }) => {
    const [hasDash, setHasDash] = useState(false);

    //Redux
    const dispatch = useDispatch();
    const attributeData = useSelector(
        state => state.attributes[attribute],
        isEqual
    );

    const validateNumber = event => {
        if (event.key !== "-" && isNaN(event.key)) {
            event.preventDefault();
        }
    };

    const updateBonus = ({ target }) => {
        setHasDash(target.value.includes("-") && parseInt(target.value) === 0);
        dispatch(updateRacialBonus(attribute, target.value));
    };

    return (
        <>
            {/* Attribute name */}
            <div>{attribute}</div>
            {/* Input for racial bonusses */}
            <div>
                <input
                    type="text"
                    onKeyPress={validateNumber}
                    onChange={updateBonus}
                    className={css.input}
                    value={(hasDash ? "-" : "") + attributeData.bonus}
                />
            </div>
            {/* Base score (configured minimum + racial bonus) */}
            <div className={css.center}>
                {limits.attribute.min + attributeData.bonus}
            </div>
            {/* Total score */}
            <div className={css.center}>
                {limits.attribute.min +
                    attributeData.bonus +
                    attributeData.points}
            </div>
            {/* Point decrease button */}
            <div>
                <FontAwesomeIcon
                    icon="minus"
                    size="xs"
                    className={css.button}
                    onClick={() => dispatch(decreaseAttribute(attribute))}
                />
            </div>
            {/* Point increase button */}
            <div>
                <FontAwesomeIcon
                    icon="plus"
                    size="xs"
                    className={css.button}
                    onClick={() => dispatch(increaseAttribute(attribute))}
                />
            </div>
        </>
    );
};

AttributeRow.propTypes = {
    attribute: PropTypes.string.isRequired
};

export default AttributeRow;
