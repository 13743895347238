import initialState from "./initialState";
import * as types from "../actions/actionTypes";
import update from "immutability-helper";

export default (state = initialState.attributes, { type, data }) => {
    switch (type) {
        case types.UPDATE_RACIAL_BONUS:
            return update(state, {
                [data.attribute]: {
                    bonus: {
                        $set: parseInt(data.value) || 0
                    }
                }
            });
        case types.INCREASE_ATTRIBUTE:
            return update(state, {
                [data.attribute]: {
                    points: { $set: state[data.attribute].points + 1 }
                }
            });
        case types.DECREASE_ATTRIBUTE:
            return update(state, {
                [data.attribute]: {
                    points: { $set: state[data.attribute].points - 1 }
                }
            });
        case types.RESET_ATTRIBUTES:
            return update(state, { $set: initialState.attributes });
        default:
            return state;
    }
};
