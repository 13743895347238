export const ATTRIBUTES = {
    STRENGTH: "STRENGTH",
    DEXTERITY: "DEXTERITY",
    CONSTITUTION: "CONSTITUTION",
    INTELLIGENCE: "INTELLIGENCE",
    WISDOM: "WISDOM",
    CHARISMA: "CHARISMA"
};

export const limits = {
    attribute: {
        min: 6,
        max: 18
    },
    maxPoints: 44
};

export const costs = [
    {
        from: 1,
        to: 6,
        value: 1
    },
    {
        from: 7,
        to: 8,
        value: 2
    },
    {
        from: 9,
        to: 10,
        value: 3
    },
    {
        from: 11,
        to: 12,
        value: 4
    }
];
