import React from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./Grid.css";
import AttributeRow from "./AttributeRow";
import { ATTRIBUTES, limits } from "../util/constants";
import { resetAttributes } from "../actions/attributeActions";
import { resetPoints } from "../actions/pointAction";

const Grid = () => {
    //Redux
    const dispatch = useDispatch();
    const pointsUsed = useSelector(state => state.points.total);

    return (
        <div className={css.root}>
            <div className={css.grid}>
                <React.Fragment>
                    <div className={css.title}>Attribute</div>
                    <div className={css.title}>Racial Bonus</div>
                    <div className={css.title}>Base</div>
                    <div className={css.title}>Total</div>
                    <div className={css.pointTotal}>
                        {pointsUsed + "/" + limits.maxPoints}
                    </div>
                </React.Fragment>
                {Object.keys(ATTRIBUTES).map((attribute, index) => (
                    <AttributeRow attribute={attribute} key={index} />
                ))}
            </div>
            <hr className={css.ruler} />
            <div className={css.buttonContainer}>
                <button
                    className={css.button}
                    onClick={() => {
                        dispatch(resetAttributes());
                        dispatch(resetPoints());
                    }}
                >
                    RESET
                </button>
            </div>
        </div>
    );
};

export default Grid;
