import initialState from "./initialState";
import * as types from "../actions/actionTypes";
import update from "immutability-helper";

export default (state = initialState.points, { type, data }) => {
    switch (type) {
        case types.UPDATE_POINT_TOTAL:
            return update(state, { total: { $set: data.points } });
        case types.RESET_POINT_TOTAL:
            return update(state, { $set: initialState.points });
        default:
            return state;
    }
};
